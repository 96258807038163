import React from 'react';
import './NameComponent.css';

const NameComponent: React.FC = () => {
    return (
        <div className='name'>
            {"Guy Green"}
        </div>
    );
};

export default NameComponent;